import { useApiFetch, useBasedFetch } from "~/api/useApi";
import { Claim, Deposit, Status } from "~/types/claim";
import { claimBody } from "~/types/calculator";

const URLS = {
  createClaimUrl: "/api/v1/claim/",
  confirmClaimUrl: (uuid: string) => `/api/v1/claim/${uuid}/confirm/`,
  fetchClaimFormUrl: (uuid: string) => `/api/v1/claim/${uuid}/form/`,
  fetchClaimRequisitesUrl: (uuid: string) => `/api/v1/claim/${uuid}/requisite/`,
  fetchClaimStatusUrl: (uuid: string) => `/api/v1/claim/${uuid}/status/`,
  getCaptchaCheck: "/api/v1/claim/captchacheck",
  uploadBillUrl: (uuid: string) => `/api/v1/claim/${uuid}/bill/`,
};

export const useClaimApi = () => {
  const createClaim = (claim: claimBody) => {
    return useApiFetch<{
      claim: string;
      verification: boolean;
      registered: boolean;
    }>(URLS.createClaimUrl, { method: "POST", body: claim });
  };

  const fetchClaimForm = (uuid: string) => {
    return useBasedFetch<Claim>(URLS.fetchClaimFormUrl(uuid));
  };

  const fetchClaimRequisites = (uuid: string) => {
    return useApiFetch<Deposit>(URLS.fetchClaimRequisitesUrl(uuid));
  };

  const fetchClaimStatus = (uuid: string) => {
    return useApiFetch<Status>(URLS.fetchClaimStatusUrl(uuid));
  };

  const confirmClaim = (uuid: string) => {
    return useApiFetch(URLS.confirmClaimUrl(uuid), { method: "POST" });
  };

  function getCaptchaCheck() {
    return useApiFetch<{
      captchaClaim: boolean;
    }>(URLS.getCaptchaCheck);
  }

  const uploadBill = (claim: string, formData: FormData) => {
    return useApiFetch(URLS.uploadBillUrl(claim), {
      method: "POST",
      body: formData,
    });
  };

  return {
    createClaim,
    fetchClaimForm,
    fetchClaimRequisites,
    fetchClaimStatus,
    confirmClaim,
    getCaptchaCheck,
    uploadBill,
  };
};
