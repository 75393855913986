import revive_payload_client_4sVQNw7RlN from "/workdir/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workdir/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workdir/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workdir/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workdir/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workdir/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workdir/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/workdir/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workdir/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workdir/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/workdir/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/workdir/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import init_grdyAU8lhf from "/workdir/plugins/init.ts";
import mitt_S0QU5gJPTl from "/workdir/plugins/mitt.ts";
import modal_UEFnUXzgTX from "/workdir/plugins/modal.ts";
import sentry_client_shVUlIjFLk from "/workdir/plugins/sentry.client.ts";
import swiperJs_Y8oXEdIPXr from "/workdir/plugins/swiperJs.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  plugin_client_OVoKJro5pc,
  init_grdyAU8lhf,
  mitt_S0QU5gJPTl,
  modal_UEFnUXzgTX,
  sentry_client_shVUlIjFLk,
  swiperJs_Y8oXEdIPXr
]