import { default as applicationseTsOxpWKkpMeta } from "/workdir/pages/account/applications.vue?macro=true";
import { default as dashboardhSzeFV929BMeta } from "/workdir/pages/account/dashboard.vue?macro=true";
import { default as promocodesUBo8JzP5NSMeta } from "/workdir/pages/account/promocodes.vue?macro=true";
import { default as indexOWAEsL0L5fMeta } from "/workdir/pages/account/referral/index.vue?macro=true";
import { default as safety4Ssk030zPPMeta } from "/workdir/pages/account/safety.vue?macro=true";
import { default as apixFVkH8tFpSMeta } from "/workdir/pages/account/shop/api.vue?macro=true";
import { default as historymdFo7pvosjMeta } from "/workdir/pages/account/shop/history.vue?macro=true";
import { default as link_45generationVSAFqUyxarMeta } from "/workdir/pages/account/shop/link-generation.vue?macro=true";
import { default as statisticsaGCQUTsP7WMeta } from "/workdir/pages/account/shop/statistics.vue?macro=true";
import { default as usageS5BlhUWsqWMeta } from "/workdir/pages/account/shop/usage.vue?macro=true";
import { default as withdrawalWZ3frEVPQBMeta } from "/workdir/pages/account/shop/withdrawal.vue?macro=true";
import { default as shopn8Thp0XF1IMeta } from "/workdir/pages/account/shop.vue?macro=true";
import { default as walletsF2C2EIHGiuMeta } from "/workdir/pages/account/wallets.vue?macro=true";
import { default as accountQy3Qfs0j0GMeta } from "/workdir/pages/account.vue?macro=true";
import { default as indexAj1kXQ7s2aMeta } from "/workdir/pages/aml/index.vue?macro=true";
import { default as indexL2wOIS17NHMeta } from "/workdir/pages/claim/[uuid]/index.vue?macro=true";
import { default as failXdaHQUDRb0Meta } from "/workdir/pages/claim/fail.vue?macro=true";
import { default as indexIPJFi2Je1FMeta } from "/workdir/pages/claim/index.vue?macro=true";
import { default as successLtUxxRtTjZMeta } from "/workdir/pages/claim/success.vue?macro=true";
import { default as indexySkdaZZjB0Meta } from "/workdir/pages/contacts/index.vue?macro=true";
import { default as indexMKwREhkAPMMeta } from "/workdir/pages/contest/index.vue?macro=true";
import { default as indexNrjrPDWjinMeta } from "/workdir/pages/discounts/index.vue?macro=true";
import { default as indexYqXC3W8KKRMeta } from "/workdir/pages/faq/index.vue?macro=true";
import { default as indexgrd9QoUdNTMeta } from "/workdir/pages/forget/index.vue?macro=true";
import { default as indexJ1sPgYGdnvMeta } from "/workdir/pages/index.vue?macro=true";
import { default as indexdt8tCTppMnMeta } from "/workdir/pages/login/index.vue?macro=true";
import { default as indexNAcF2OimVeMeta } from "/workdir/pages/monitoring/index.vue?macro=true";
import { default as _91slug_93YwoilbelrwMeta } from "/workdir/pages/news/[slug].vue?macro=true";
import { default as index2Y6faI97hTMeta } from "/workdir/pages/news/index.vue?macro=true";
import { default as indexo45LdNCFk2Meta } from "/workdir/pages/promocode/index.vue?macro=true";
import { default as indexZdhdgNeEP1Meta } from "/workdir/pages/recover/index.vue?macro=true";
import { default as indexRkfcqitLlkMeta } from "/workdir/pages/register/index.vue?macro=true";
import { default as indexwEYHUqa81RMeta } from "/workdir/pages/reserves/index.vue?macro=true";
import { default as indexk3qcO1cw9bMeta } from "/workdir/pages/rules/index.vue?macro=true";
import { default as indexH6U53uCxdBMeta } from "/workdir/pages/shops/index.vue?macro=true";
import { default as indexPDB9tVlXe9Meta } from "/workdir/pages/users/index.vue?macro=true";
import { default as _91uuid_93FBaU04Y9qoMeta } from "/workdir/pages/verify/[uuid].vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountQy3Qfs0j0GMeta || {},
    component: () => import("/workdir/pages/account.vue"),
    children: [
  {
    name: "account-applications",
    path: "applications",
    component: () => import("/workdir/pages/account/applications.vue")
  },
  {
    name: "account-dashboard",
    path: "dashboard",
    component: () => import("/workdir/pages/account/dashboard.vue")
  },
  {
    name: "account-promocodes",
    path: "promocodes",
    component: () => import("/workdir/pages/account/promocodes.vue")
  },
  {
    name: "account-referral",
    path: "referral",
    component: () => import("/workdir/pages/account/referral/index.vue")
  },
  {
    name: "account-safety",
    path: "safety",
    component: () => import("/workdir/pages/account/safety.vue")
  },
  {
    name: "account-shop",
    path: "shop",
    component: () => import("/workdir/pages/account/shop.vue"),
    children: [
  {
    name: "account-shop-api",
    path: "api",
    component: () => import("/workdir/pages/account/shop/api.vue")
  },
  {
    name: "account-shop-history",
    path: "history",
    component: () => import("/workdir/pages/account/shop/history.vue")
  },
  {
    name: "account-shop-link-generation",
    path: "link-generation",
    component: () => import("/workdir/pages/account/shop/link-generation.vue")
  },
  {
    name: "account-shop-statistics",
    path: "statistics",
    component: () => import("/workdir/pages/account/shop/statistics.vue")
  },
  {
    name: "account-shop-usage",
    path: "usage",
    component: () => import("/workdir/pages/account/shop/usage.vue")
  },
  {
    name: "account-shop-withdrawal",
    path: "withdrawal",
    component: () => import("/workdir/pages/account/shop/withdrawal.vue")
  }
]
  },
  {
    name: "account-wallets",
    path: "wallets",
    component: () => import("/workdir/pages/account/wallets.vue")
  }
]
  },
  {
    name: "aml",
    path: "/aml",
    component: () => import("/workdir/pages/aml/index.vue")
  },
  {
    name: "claim-uuid",
    path: "/claim/:uuid()",
    component: () => import("/workdir/pages/claim/[uuid]/index.vue")
  },
  {
    name: "claim-fail",
    path: "/claim/fail",
    component: () => import("/workdir/pages/claim/fail.vue")
  },
  {
    name: "claim",
    path: "/claim",
    meta: indexIPJFi2Je1FMeta || {},
    component: () => import("/workdir/pages/claim/index.vue")
  },
  {
    name: "claim-success",
    path: "/claim/success",
    component: () => import("/workdir/pages/claim/success.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/workdir/pages/contacts/index.vue")
  },
  {
    name: "contest",
    path: "/contest",
    component: () => import("/workdir/pages/contest/index.vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/workdir/pages/discounts/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/workdir/pages/faq/index.vue")
  },
  {
    name: "forget",
    path: "/forget",
    meta: indexgrd9QoUdNTMeta || {},
    component: () => import("/workdir/pages/forget/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workdir/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexdt8tCTppMnMeta || {},
    component: () => import("/workdir/pages/login/index.vue")
  },
  {
    name: "monitoring",
    path: "/monitoring",
    component: () => import("/workdir/pages/monitoring/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/workdir/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/workdir/pages/news/index.vue")
  },
  {
    name: "promocode",
    path: "/promocode",
    component: () => import("/workdir/pages/promocode/index.vue")
  },
  {
    name: "recover",
    path: "/recover",
    meta: indexZdhdgNeEP1Meta || {},
    component: () => import("/workdir/pages/recover/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexRkfcqitLlkMeta || {},
    component: () => import("/workdir/pages/register/index.vue")
  },
  {
    name: "reserves",
    path: "/reserves",
    component: () => import("/workdir/pages/reserves/index.vue")
  },
  {
    name: "rules",
    path: "/rules",
    component: () => import("/workdir/pages/rules/index.vue")
  },
  {
    name: "shops",
    path: "/shops",
    component: () => import("/workdir/pages/shops/index.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/workdir/pages/users/index.vue")
  },
  {
    name: "verify-uuid",
    path: "/verify/:uuid()",
    component: () => import("/workdir/pages/verify/[uuid].vue")
  }
]